<template>
  <div class="expense">
    <h2>报考费用</h2>
    <p>{{ message }}</p>
  </div>
</template>

<script>
export default {
  data () {
    return {
      message:
        '报名费的收取严格按照广东省物价局的标准执行！根据规定，广东成人高考报名费为每人每科37元。高起专、专升本考三科，报名费111元（此费用需上交广东省教育厅）。'
    }
  }
}
</script>

<style lang="scss" scoped>
.expense {
  padding: 70px 0px;
  line-height: 30px;
  font-size: 16px;
}
</style>
